<template>
  <div id="SolarPlants">
    <Hero src="../assets/solar-plants/hero.jpg">
      <h1>Saulės parkai</h1>
    </Hero>
    <Wrap class="lg">
      <Sect class="projects">
        <div v-if="!projects || !projects.length" class="loadingScreen">
          <Loader class="loadingScreen" />
        </div>
        <template v-else>
          <CardProject :project="projects[0]" />
          <Row>
            <Column
              v-for="(project, index) in projects.slice(1, projects.length)"
              :key="index"
              :lg="6">
              <CardProject
                :project="project" />
            </Column>
          </Row>
        </template>
      </Sect>
    </Wrap>
  </div>
</template>

<script>
import CardProject from './components/CardProject'
import Hero from './components/landing/Hero'
import Meta from './mixins/Meta'
// import ProjectMocker from 'mixins/ProjectMocker'
import ProjectBundler from 'mixins/ProjectBundler'

export default {
  mixins: [
    ProjectBundler,
    Meta(
      {
        inner: 'Saulės parkai',
        separator: '|',
        complement: 'Saulės bendruomenė',
      },
      undefined,
      require('@/assets/social/homepage.jpg'),
    ),
  ],
  components: {
    CardProject,
    Hero,
  },
}
</script>

<style lang="scss">
#SolarPlants {
  background: $white-ter;

  .Hero {
    @include roundSvg('../assets/ellipse.svg');
  }

  .loadingScreen {
    height: 20vh;
  }

  h1 {
    @include dotBefore(8rem);
  }

  .CardProject {
    margin-bottom: 2rem;
  }
}

</style>
